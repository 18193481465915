<template>
  <div
    class="flex flex-col"
    :class="containerClassname"
  >
    <span
      v-if="label"
      class="px-1 pb-1 text-space-dark-blue"
      :class="labelClassname"
    >
      {{ label.toString() }}
    </span>
    <div
      class="wrapper w-full relative py-4 px-5 rounded-full border-none bg-space-dark-blue flex"
      :class="wrapperClassname"
    >
      <input
        ref="input"
        :type="type"
        :class="inputClassname"
        :placeholder="placeholder"
        :value="value"
        :disabled="disabled"
        @input="onInput"
      >
      <div class="absolute right-4 top-1/2 transform -translate-y-1/2 h-8 w-8">
        <div
          ref="visibilityIcon"
          class="relative w-full h-full"
        >
          <img
            v-if="type === 'password'"
            class="visibility-icon"
            :src="require(hide ? '@/assets/icons/icon_show.svg' : '@/assets/icons/icon_hide.svg')"
            :data-src="require(hide ? '@/assets/icons/icon_show.svg' : '@/assets/icons/icon_hide.svg')"
            :alt="!hide ? 'showText' : 'Hide text'"
            @click="hide = !hide"
          >
        </div>
      </div>
    </div>
    <span
      v-if="errors && field && errors[field] && errors[field] !== ''"
      class="text-space-darker-red px-1 pt-1"
    >
      {{ errors[field] }}
    </span>
  </div>
</template>

<script>
import VueI18n from "vue-i18n";

export default {
  name: "TextFormInput",
  props: {
    placeholder: String,
    field: String,
    type: String,
    errors: Object,
    value: {
      type: String,
      required: false
    },
    label: [String, VueI18n.TranslateResult],
    inputClassname: {
      type: String,
      default: "text-white"
    },
    wrapperClassname: String,
    labelClassname: String,
    containerClassname: String,
    disabled: Boolean
  },
  emits: ['input'],
  data() {
    return {
      hide: true
    };
  },
  watch: {
    hide: function (value) {
      if (value) {
        this.$refs.input.type = "password";
      } else {
        this.$refs.input.type = "text";
      }
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    }
  }
};
</script>

<style scoped>
.wrapper:not(.shadow-none){
  box-shadow: 0 0 20px rgba(77, 42, 156, 0.43);
}

input{
  @apply flex-1 px-1 w-full border-none outline-none bg-transparent;
}

.visibility-icon {
  cursor: pointer;
  overflow: hidden;
  position: relative;
  transition: background 600ms;
  border-radius: 9999px;
  padding: 2px;
}

.visibility-icon:active {
  background-color: rgba(255, 255, 255, 0.5);
  background-size: 150%;
  transition: background 0s;
}
</style>
