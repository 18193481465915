<template>
  <HomeLayout>
    <template #content>
      <div class="flex flex-col items-center flex-grow justify-center mb-36 lg:w-8/12 xl:w-6/12 mx-auto">
        <div class="bg-white rounded-[3em] justify-center p-8 px-12 w-full">
          <div class="flex flex-col space-y-8 p-8" v-if="$route.query.reset_token">
            <span class="text-space-green text-2xl font-raleway-extra-bold">
              {{ $t('resetPassword.resetPasswordNewPasswordTitle') }}
            </span>
            <TextFormInput
              v-model="resetData.password"
              :label="$t('registrationForm.password')"
              :placeholder="$t('registrationForm.password')"
              :errors="errors"
              type="password"
              field="password"
            />
            <TextFormInput
              v-model="resetData.confirmPassword"
              :label="$t('registrationForm.confirmPassword')"
              :placeholder="$t('registrationForm.confirmPassword')"
              :errors="errors"
              type="password"
              field="confirmPassword"
            />
            <div class="flex justify-center">
              <PrimaryButton
                :text="$t('common.validate')"
                @click="resetPassword"
                class="!bg-space-dark-blue text-white font-raleway-extra-bold"
              />
            </div>
          </div>
          <div class="flex flex-col" v-else>
            <div class="flex flex-col space-y-8" v-if="!showResetMessage">
              <span class="text-space-green text-2xl font-raleway-extra-bold">
                {{ $t('resetPassword.resetPasswordTitleRequest') }}
              </span>
              <TextFormInput
                v-model="email"
                :label="$t('registrationForm.email')"
                :placeholder="$t('registrationForm.email')"
                :errors="errors"
                field="email"
              />

              <PrimaryButton
                :text="$t('common.validate')"
                @click="requestResetPassword"
                class="!bg-space-dark-blue text-white font-raleway-extra-bold text-xl self-center"
              />
            </div>
            <div class="flex flex-col items-center space-y-8" v-if="showResetMessage">
              <p class="text-space-dark-blue text-xl font-raleway-extra-bold text-justify">
                {{ $t('resetPassword.resetPasswordMessage') }}
              </p>
              <PrimaryButton
                :text="$t('404.button').toString()"
                bg-color="bg-space-dark-blue"
                classes="mt-8 text-white"
                @click="() => $router.push(ROUTE_HOME)"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </HomeLayout>
</template>
<script>
import HomeLayout from "@/layouts/HomeLayout.vue";
import TextFormInput from "@/components/inputs/TextFormInput.vue";
import UserService from "@/services/userService";
import {ROUTE_HOME} from "@/router";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import FormService from "@/services/formService";

export default {
  computed: {
    ROUTE_HOME() {
      return ROUTE_HOME;
    }
  },
  components: {PrimaryButton, TextFormInput, HomeLayout},
  data() {
    return {
      resetData: {
        password: "",
        confirmPassword: "",
      },
      email: "",
      errors: {
        email: false,
        password: false,
      },
      showResetMessage: false,
    };
  },
  methods: {
    resetPassword() {
      this.errors = UserService.validateResetPasswordForm(this.resetData);

      if (FormService.hasErrors(this.errors)) return;

      UserService.resetPassword({
        password: this.resetData.password,
        confirmPassword: this.resetData.confirmPassword,
        resetToken: this.$route.query.reset_token,
      });
    },
    requestResetPassword() {
      const errors = {
        email: FormService.hasValidEmail(this.email),
      };
      this.errors = errors;
      if (FormService.hasErrors(errors)) return;

      UserService.resetPasswordRequest(this.email)
        .then(() => {
          this.showResetMessage = true;
        });
    },
  },
};
</script>
