import i18n from '../i18n/i18n';
import {API} from "@/utils/api";
import Router, {ROUTE_HOME} from '../router';
import Vue from "vue";
import {TOAST_DEFAULT} from "@/constants/toast/toastConstants";
import {store} from "@/store/store";
import {LOGIN_ACTION} from "@/store/modules/userModule/actions";
import {isNull} from "../utils/isNull";
import FormService from "@/services/formService";

class UserService {
  /**
     * Login user
     * @param formData
     * @param route
     * @returns {Promise<AxiosResponse<any>>}
     */
  signIn(formData, route) {
    const userId = localStorage.getItem('user');

    return API.post(`auth/login?id=${userId}`, formData)
      .then(async (response) => {
        if ('id' in response.data) {
          await store.dispatch(LOGIN_ACTION, {
            is_guest: false,
            ...response.data
          });

          if (!isNull(route.query.redirect)) {
            await Router.push({name: route.query.redirect, query: {pin: route.query.pin}});
            return;
          }
          await Router.push(ROUTE_HOME.path);
        }
      })
      .catch(({response}) => {
        if (response.status === 401 || response.status === 404 || response.status === 400) {
          return i18n.t(`signInForm.errors.${response.data.code}`);
        }
      });
  }

  /**
     * Register user
     * @param formData
     * @returns {Promise<AxiosResponse<any>>}
     */
  async signUp(formData, recaptchaToken) {
    let url = 'auth/register';
    const user = store.getters.getUser;
    if (!isNull(user.id)) {
      url += `?id=${user.id}`;
    }

    return API.post(url, {
      ...formData,
      isGuest: false
    },  {
      headers: {
        'X-Recaptcha-Token': recaptchaToken
      }
    });
  }

  /**
     * Register a guest user
     * @param pseudo
     * @returns void
     */
  async signUpGuest(pseudo) {
    const user = await API.post(`user/guest`, {pseudo: pseudo}).then(res => res.data);

    if (isNull(user.id)) {
      return;
    }

    await store.dispatch(LOGIN_ACTION, {
      id: user.id,
      pseudo: user.pseudo,
      token: user.token,
      is_guest: user.isGuest
    });
  }

  /**
     * Activate user account
     * @param token
     * @returns {Promise<void>}
     */
  async activateUser(token) {
    API.get('/auth/activate', {
      headers: {
        Authorization: 'Bearer '+ token
      }
    })
      .then(async (response) => {
        Vue.$toast.success(i18n.t('activation.account_activated').toString(), TOAST_DEFAULT);
        await store.dispatch(LOGIN_ACTION, response.data);
        await Router.push(ROUTE_HOME.path);
      })
      .catch(({response}) => {
        Vue.$toast.error(i18n.t(`activation.errors.${response.data.code}`).toString(), TOAST_DEFAULT);
        if (response.data.code === 'user_already_active') {
          Router.push(ROUTE_HOME.path);
        }
      });
  }
  resetPassword(formData) {
    return API.post('/auth/reset-password', formData)
      .then(async () => {
        Vue.$toast.success(i18n.t('resetPassword.success').toString(), TOAST_DEFAULT);
        await Router.push(ROUTE_HOME.path);
      })
      .catch(({response}) => {
        Vue.$toast.error(i18n.t(`resetPassword.errors.${response?.data?.message === 'reset_token_expired' ? 'reset_token_expired' : 'default'}`).toString(), TOAST_DEFAULT);
      });
  }

  resetPasswordRequest(email) {
    return API.post('/auth/reset-password-request', {email: email});
  }

  validateResetPasswordForm(formData) {
    return {
      password: FormService.hasValidPassword(formData.password),
      confirmPassword: FormService.hasValidConfirmPassword(formData.password, formData.confirmPassword)
    };
  }
  async sendContactEmail(formData, recaptchaToken) {
    API.post('/user/contact', formData, {
      headers: {
        'X-Recaptcha-Token': recaptchaToken
      }
    })
      .then(() => {
        Vue.$toast.success(i18n.t('contact.success').toString(), TOAST_DEFAULT);
        Router.push(ROUTE_HOME);
      })
      .catch(({response}) => {
        Vue.$toast.error(i18n.t(`contact.errors.${response.data.code}`).toString(), TOAST_DEFAULT);
      });
  }

  async getUser(id) {
    return API.get(`user/${id}`).then(res => res.data);
  }

  /**
   * Update user
   * @param id
   * @param formData
   * @return {Promise<AxiosResponse<any>>}
   */
  async updateUserProfile(formData) {
    return API.put(`user/profile`, formData).then(res => res.data)
      .then((user) => {
        store.dispatch(LOGIN_ACTION, {
          ...store.getters.getUser,
          email: user.email,
          pseudo: user.pseudo,
          avatar: user.avatar
        });
        Vue.$toast.success(i18n.t('profileForm.success').toString(), TOAST_DEFAULT);
      });
  }

  validateUpdateProfileForm(formData) {
    return {
      email: FormService.hasValidEmail(formData.email),
      pseudo: FormService.checkNotEmpty(formData.pseudo),
    };
  }

  validateUpdatePasswordForm(formData) {
    return {
      oldPassword: FormService.checkNotEmpty(formData.oldPassword),
      password: FormService.hasValidPassword(formData.password),
      confirmPassword: FormService.hasValidConfirmPassword(formData.password, formData.confirmPassword)
    };
  }
}

export default new UserService();
