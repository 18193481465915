import i18n from '../i18n/i18n';

class FormService {
  /**
     * Check if email is valid
     * @param email
     * @returns {VueI18n.TranslateResult|string}
     */
  hasValidEmailFormat(email) {
    return this.isEmpty(email) ? i18n.t('common.errors.required_field') : (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email) ? i18n.t('registrationForm.errors.emailNotValid') : '');
  }

  /**
     * Check if confirm password is valid
     * @returns {VueI18n.TranslateResult|string}
     */
  hasValidConfirmPassword(password, confirmPassword) {
    return this.isEmpty(confirmPassword) ? i18n.t('common.errors.required_field') : (password !== confirmPassword ? i18n.t('registrationForm.errors.passwordsNotMatch') : '');
  }

  /**
     * Check if password is valid
     * @returns {VueI18n.TranslateResult|string}
     */
  hasValidPassword(password) {
    return this.isEmpty(password) ? i18n.t('common.errors.required_field') : /^(?=(?:[^a-z]*[a-z]){2})(?=(?:[^0-9]*[0-9]){2})(?=.*[!-/:-@[-`{-~]).{10,}$/i.test(password) ? '' : i18n.t('registrationForm.errors.passwordNotStrong');
  }

  /**
     * Check if value is empty
     * @param value
     * @returns {boolean}
     */
  isEmpty(value) {
    return !value || value.length === 0;
  }

  hasValidEmail(value) {
    return this.isEmpty(value) ? i18n.t('common.errors.required_field') : (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ? i18n.t('common.errors.email_not_valid') : '');
  }

  /**
     * Return error message if value empty
     * @param value
     * @returns {VueI18n.TranslateResult|string}
     */
  checkNotEmpty(value) {
    return this.isEmpty(value) ? i18n.t('common.errors.required_field') : '';
  }

  /**
     * Check if error object not empty
     * @param errors
     * @returns {unknown}
     */
  hasErrors(errors) {
    return Object.values(errors).some(value => value !== '' && value !== null);
  }
}

export default new FormService();
